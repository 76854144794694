import React from 'react';

import underConstruction from '../../assets/underConstruction.gif';

import './horario-preco.styles.scss';

const HorarioPreco = () => (
    <div className='horario-preco'>
        <img src={underConstruction} alt="underConstruction" className='under-construction'/>
        <h1>UNDER CONSTRUCTION</h1>
    </div>
)

export default HorarioPreco;