import React from 'react';

import underConstruction from '../../assets/underConstruction.gif';

import './quem-somos.styles.scss';

const QuemSomos = () => (
    <div className='quem-somos'>
        <img src={underConstruction} alt="underConstruction" className='under-construction'/>
        <h1>UNDER CONSTRUCTION</h1>
    </div>
)

export default QuemSomos;