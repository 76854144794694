import React from 'react';

//import ImageGalery from '../../components/image-galery/image-galery.component';

import Lego from '../../assets/presentation.jpeg';

import './homepage.styles.scss';

const HomePage = () => (
    <div className='homepage'>
        <div className='component'>
            <h1 className='textTitle'>André Escobar</h1>
            <h1 className='textSubtitle'>Escola de Esgrima</h1>
            {/*<ImageGalery className='image'/>*/}
            <img src={Lego} alt='cenas' className='image' />
        </div>

        <div className='component'>
            <p className='text'>CLASSES DE APRENDIZAGEM DE FLORETE</p>
            <p className='text'>HORARIO: SEGUNDAS E QUARTAS</p>
            <p className='text'>CRIANÇAS: 18H - 19H</p>
            <p className='text'>ADULTOS: 19H - 20H30</p>
        </div>
        
        <div className='component'>
            <h2 className='text'>Pavilhão Desportivo da Escola Quinta de Marrocos</h2>
            <h3 className='text'>Estrada de Benfica</h3>
        </div>
        
        <div className='component'>
            <h4 className='text'> contactos </h4>
            <p className='text'>email : aescobaresgrima@gmail.com</p>
            <p className='text'>instagram : @aescobaresgrima</p>
        </div>
    </div>
);

export default HomePage;