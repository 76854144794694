import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../assets/logoBlack_EEAE.jpeg';

import './header.styles.scss';

const Header = () => (
    <div className='header'>
        <Link className='logo-container' to="/">
            <img src={Logo} alt="Logo" className='logo-container'/>
        </Link>
        <div className='options'>
            <Link className='option' to='/quemsomos'>
                QUEM SOMOS
            </Link>
            <Link className='option' to='/horariopreco'>
                HORARIO E PREÇO
            </Link>
            <Link className='option' to='/signin'>
                SIGN IN
            </Link>
            <Link className='option' to='/contactos'>
                CONTACTOS
            </Link>
        </div>
    </div>
)

export default Header;