import React from 'react';
import Particles
 from 'react-tsparticles';
import './particles.styles.scss';

import Logo from '../../assets/semfundo.png';

/*params imported from 'https://vincentgarreau.com/particles.js/' */

class ParticlesJS extends React.Component {
    render() {
        return (
            <div>
            <Particles
                className='particles'
                params={{
                    "particles": {
                    "number": {
                        "value": 200,
                        "density": {
                        "enable": true,
                        "value_area": 800
                        }
                    },
                    "color": {
                        "value": "#000000"
                    },
                    "shape": {
                        "type": "image",
                        "stroke": {
                        "width": 0,
                        "color": "#000000"
                        },
                        "polygon": {
                        "nb_sides": 5
                        },
                        "image": {
                        "src": Logo,
                        "width": 100,
                        "height": 100
                        }
                    },
                    "opacity": {
                        "value": 0.5,
                        "random": false,
                        "anim": {
                        "enable": false,
                        "speed": 1,
                        "opacity_min": 0.1,
                        "sync": false
                        }
                    },
                    "size": {
                        "value": 19.729625216039402,
                        "random": true,
                        "anim": {
                        "enable": false,
                        "speed": 1,
                        "size_min": 0.1,
                        "sync": false
                        }
                    },
                    "line_linked": {
                        "enable": true,
                        "distance": 150,
                        "color": "#ffffff",
                        "opacity": 0.4,
                        "width": 1
                    },
                    "move": {
                        "enable": true,
                        "speed": 3,
                        "direction": "none",
                        "random": false,
                        "straight": false,
                        "out_mode": "out",
                        "bounce": false,
                        "attract": {
                        "enable": false,
                        "rotateX": 600,
                        "rotateY": 1200
                        }
                    }
                    },
                    "interactivity": {
                    "detect_on": "canvas",
                    "events": {
                        "onhover": {
                        "enable": true,
                        "mode": "repulse"
                        },
                        "onclick": {
                        "enable": true,
                        "mode": "push"
                        },
                        "resize": true
                    },
                    "modes": {
                        "grab": {
                        "distance": 400,
                        "line_linked": {
                            "opacity": 1
                        }
                        },
                        "bubble": {
                        "distance": 400,
                        "size": 40,
                        "duration": 2,
                        "opacity": 8,
                        "speed": 3
                        },
                        "repulse": {
                        "distance": 200,
                        "duration": 0.4
                        },
                        "push": {
                        "particles_nb": 4
                        },
                        "remove": {
                        "particles_nb": 2
                        }
                    }
                    },
                    "retina_detect": true
                }}
            />
            </div>
        )
    }
}

export default ParticlesJS;
