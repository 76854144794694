import React from 'react';
import {Routes, Route} from 'react-router-dom';

import './App.css';
import HomePage from './pages/homepage/homepage.component';
import QuemSomos from './pages/Quem-Somos/quem-somos.component';
import HorarioPreco from './pages/horario-preco/horario-preco.component';
import SigninAndSignup from './pages/signin-and-signout/signin-and-signout.component';
import Contactos from './pages/contactos/contactos.component';
import ParticlesJs from './components/particles/particles.component';
import Header from './components/header/header.component';
import Footer from './components/footer/footer.component';

class App extends React.Component {
  constructor() {
    super();
    this.state= {
      currentUser: null
    }
  }

  render() {
    return (
      <div>
        <ParticlesJs />
        <Header className='header'/>
        <Routes className='page'>
          <Route exact path='/' element={HomePage}/>
          <Route path='/quemsomos' element={QuemSomos}/>
          <Route path='/horariopreco' element={HorarioPreco}/>
          <Route path='/signin' element={SigninAndSignup}/>
          <Route path='/contactos' element={Contactos}/>
        </Routes>
        <HomePage />
        <Footer className='footer'/>
      </div>
    )
  }
}

export default App;
